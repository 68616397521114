<template>
  <section class="invoice-preview-wrapper">
    <!-- Alert: No item found -->
    <template v-if="!order_details">
      <h4 class="alert-heading">
        Error fetching order data
      </h4>
      <div class="alert-body">
        No order found with this order id. Check
        <b-link class="alert-link" :to="{ name: 'order-list' }">
          Order List
        </b-link>
        for other orders.
      </div>
    </template>
    <template v-if="!isPageVisible">
      <h1 class="alert-heading">
        Invoice Not Available Due To Incomplete Payment
      </h1>
    </template>

    <b-row v-else class="invoice-preview">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12" xl="9" md="8" id="to-print">
        <b-card no-body class="invoice-preview-card">
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <b-img :src="logo" alt="logo" class="fp_logo mb-5"/>
                  
                </div>
                <h6 class="mb-25 mt-5">
                  {{ order_details.user_firstname }} {{ order_details.user_lastname }}
                </h6>
                <p class="card-text mb-25">
                  {{ order_details.billing_address_line_1 }}, {{ order_details.billing_address_line_2 }}
                </p>
                <p class="card-text mb-25">
                  {{ order_details.billing_city }}, {{ order_details.billing_state }}, {{ order_details.billing_country
                  }}
                </p>
                <p class="card-text mb-25">
                  {{ order_details.billing_pincode }}
                </p>
                <p class="card-text mb-0">
                  {{ order_details.user_email }}
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 ">
                <div class="invoice-date-wrapper d-flex" style="gap: 10px;">
                  <h4 class="invoice-title mb-2">
                    Invoice
                    <span class="invoice-number">#{{ order_details.so_id }}</span>
                  </h4>
                </div>
                <div class="invoice-date-wrapper d-flex" style="gap: 10px;">
                  <p class="invoice-date-title">
                    Payment Date:
                  </p>
                  <p class="invoice-date">
                    {{ moment(order_details.created_at).format("DD MMM, YYYY") }}
                  </p>
                </div>
                
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body class="invoice-padding pt-0">
            <b-row class="invoice-spacing">
              <!-- Col: Invoice To -->
              <b-col cols="12" xl="6" class="p-0">
                <h6 class="mb-2">
                  Shipping To:
                </h6>
                <h6 class="mb-25">
                  {{ order_details.user_firstname }} {{ order_details.user_lastname }}
                </h6>
                <p class="card-text mb-25">
                  {{ order_details.shipping_address_line_1 }}, {{ order_details.shipping_address_line_2 }}
                </p>
                <p class="card-text mb-25">
                  {{ order_details.shipping_city }}, {{ order_details.shipping_state }}, {{
                    order_details.shipping_country
                  }}
                </p>
                <p class="card-text mb-25">
                  {{ order_details.shipping_pincode }}
                </p>
                <p class="card-text mb-0">
                  {{ order_details.user_email }}
                </p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col xl="6" cols="12" class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end">
                <div>
                  <h6 class="mb-2">
                    Payment Details:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Total Due:
                        </td>
                        <td><span class="font-weight-bold">₹{{ order_details.so_total_amount }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Bank name:
                        </td>
                        <td>Sample Bank</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Country:
                        </td>
                        <td>{{ order_details.billing_country }}</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          IBAN:
                        </td>
                        <td>123456789</td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          SWIFT code:
                        </td>
                        <td>ABCDEFGH</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite responsive :items="[order_details]" :fields="fields">
            <template #cell(so_quantity)="data">
              <b-card-text>
                ₹{{ data.item.so_quantity }}
              </b-card-text>
            </template>
            <template #cell(so_quantity_amount)="data">
              <b-card-text>
                ₹{{ data.item.so_quantity_amount }}
              </b-card-text>
            </template>
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
              <!-- Col: Sales Person -->
              <!-- <b-col cols="12" md="6" class="mt-md-0 mt-3" order="2" order-md="1">
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">Salesperson:</span>
                  <span class="ml-75">Alfie Solomons</span>
                </b-card-text>
              </b-col> -->

              <!-- Col: Total -->
              <b-col cols="12" class="mt-md-6 d-flex justify-content-end" order="1" order-md="2">
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item d-flex justify-content-between" style="gap: 20px;">
                    <p class="invoice-total-title">
                      Subtotal:
                    </p>
                    <p class="invoice-total-amount font-weight-bold">
                      ₹{{ order_details.so_quantity_amount }}
                    </p>
                  </div>
                  <div class="invoice-total-item d-flex justify-content-between" style="gap: 20px;">
                    <p class="invoice-total-title">
                      Additional Charges:
                    </p>
                    <p class="invoice-total-amount font-weight-bold">
                      ₹{{ order_details.so_addition_charges }}
                    </p>
                  </div>
                  <hr class="my-1">
                  <div class="invoice-total-item d-flex justify-content-between" style="gap: 20px;">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount font-weight-bold">
                      ₹{{ order_details.so_total_amount }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0 text-center">
            <span>It was a pleasure working with you , Thank You.</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col cols="12" md="4" xl="3" class="invoice-actions">
        <b-card>
          <!-- Button: Download -->
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" class="mb-75" block>
            Download
          </b-button>

          <!-- Button: Print -->
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-primary" class="mb-75" block
            @click="printInvoice">
            Print
          </b-button>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCard,
  BAlert,
  BRow,
  BCol,
  BCardBody,
  BTableLite,
  BButton,
  BLink,
  VBToggle,
  BCardText,
  BImg
} from "bootstrap-vue";
import AliffLogo from "@/theme-variables/assets/Logo.png";
import CommonServices from "@/apiServices/CommonServices";
import moment from "moment";
import Ripple from 'vue-ripple-directive'
import { FILESURL } from "@/config";
import store from "@/store";

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BCard,
    BAlert,
    BRow,
    BCol,
    BCardBody,
    BCardText,
    BTableLite,
    BButton,
    BLink,
    BImg,

  },
  data() {
    return {
      so_id: null,
      order_details: {},
      fields: [
        { key: 'so_id', label: 'Order ID' },
        { key: 'sp_name', label: 'Product' },
        { key: 'so_currency', label: 'Currency' },
        { key: 'so_quantity', label: 'Quantity' },
        { key: 'so_quantity_amount', label: 'Quanity Amount' },
      ],
    };
  },
  computed: {
    isPageVisible() {
      const isPaid = this.order_details.transaction_status
      if (isPaid === 'success') {
        return true
      }
      return false
    },
    logo() {
      const user_details = this.$store.getters["user/getUserDetails"];
      if (user_details.brand_logo) {
        return `${FILESURL}${user_details.brand_logo}`;
      }
      return AliffLogo;
    },
  },
  methods: {
    async getSingleShopOrder(so_id) {
      try {
        const response = await CommonServices.getSingleShopOrder({ so_id });
        if (response.data.status) {
          this.order_details = response.data.data;
        }
      } catch (error) {
        console.error("Error fetching order details", error);
      }
    },
    printInvoice() {
        document.querySelectorAll('.dont-print').forEach(el => {
        el.classList.add('print-show');
        el.classList.remove('dont-print');
      });

      window.print();

      setTimeout(() => {
        document.querySelectorAll('.print-show').forEach(el => {
          el.classList.add('dont-print');
          el.classList.remove('print-show');
        });
      }, 5000); 
    },


    moment
  },
  beforeMount() {
    const { so_id } = this.$route.params;
    if (!isNaN(so_id)) {
      this.so_id = so_id;
      this.getSingleShopOrder(so_id);
    } else {
      console.error("Order ID is not a number", so_id);
    }
  }
};
</script>

<style lang="scss" scoped>
.fp_logo {
  height: 50px;
  width: 50px;
  margin-bottom: 35px;
  // left: 41px;

  // bottom: 10px;
}
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
<style lang="scss" scoped>
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
    display: block !important;
  }

  nav.header-navbar {
    display: none;
  }

  .main-menu {
    display: none;
  }

  .content-header {
    display: none !important;
  }

  .header-navbar-shadow {
    display: none !important;
  }

  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .customizer-toggle {
    display: none !important;
  }

  .invoice-preview-wrapper {
    display: block !important;

    body {
      display: block !important;
    }

    .invoice-actions {
      display: none !important;
    }
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            >.col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>